import { useEffect, type FC } from "react";
import { isNullable } from "@/types/isNullable";
import type { EmbeddedVideoPlayer as XpaEmbeddedVideoPlayer } from "@motain/xpa-proto-files-web/lib/types/embedded_video_player";
import { isNonNullable } from "@/types/isNonNullable";
import { Title } from "@/xpa-components/title/Title";
import classNames from "classnames";
import styles from "./EmbeddedVideoPlayer.module.scss";
import { LogoLoadingSpinner } from "@/components/logo-loading-spinner/LogoLoadingSpinner";
import Script from "next/script";

const EMBEDDED_VIDEO_SCRIPT =
  "https://embed.onefootball.com/widget/strategyRulesPlayer/onefootball-cweb.js";

const JWP_PLACEMENT_ID = "j54gpTRI";

export type EmbeddedVideoPlayerProps = XpaEmbeddedVideoPlayer & {
  className?: string;
  maxSize?: string;
};

declare global {
  interface Window {
    jwplacements?: unknown;
  }
}

const EmbeddedVideoPlayer: FC<EmbeddedVideoPlayerProps> = (props) => {
  const { playlistId, title, className, maxSize = "34.5rem" } = props;
  const timestamp = Date.now();

  useEffect(() => {
    return () => {
      // We remove this to ensure the player initializes correctly when the script is reloaded
      delete window.jwplacements;
    };
  }, []);

  if (isNullable(playlistId)) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container)}
      style={{ "--max-width": maxSize }}
    >
      {isNonNullable(title) && <Title {...title} className={className}></Title>}
      <div className={styles.videoPlayerWrapper}>
        <div
          data-testid="video-player"
          id={playlistId}
          data-jw-placement-id={JWP_PLACEMENT_ID}
          className={classNames(
            "of-widget of-widget-eyJwIjoib25lZm9vdGJhbGwtY3dlYiIsImNvbnRlbnQiOiJ2aWRlby1wbGF5ZXIifQ==",
            styles.videoPlayer,
          )}
        >
          <Script
            // we pass this random query param to force the script to reload
            src={`${EMBEDDED_VIDEO_SCRIPT}?random=${timestamp}`}
            strategy="lazyOnload"
          />
        </div>
        <div data-testid="loading-spinner" className={styles.loading}>
          <LogoLoadingSpinner></LogoLoadingSpinner>
        </div>
      </div>
    </div>
  );
};

export { EmbeddedVideoPlayer };
