import type { FC } from "react";
import type { ArticleParagraphComponent } from "@motain/xpa-proto-files-web/lib/types/news";
import styles from "./ArticleParagraph.module.scss";

export const ArticleParagraph: FC<NonNullable<ArticleParagraphComponent>> = (
  data,
) => {
  return (
    <>
      {data.content && (
        <div
          className={styles.articleParagraph}
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      )}
    </>
  );
};
