import type { FC } from "react";
import type { ArticleListComponent } from "@motain/xpa-proto-files-web/lib/types/news";
import styles from "./ArticleList.module.scss";

export const ArticleList: FC<NonNullable<ArticleListComponent>> = (data) => {
  return (
    <>
      {data.isOrdered ? (
        <ol className={styles.ofListOrdered}>
          {data.items.map((itemList, index) => {
            return (
              <li
                key={`list-key-ordered-${index}`}
                className={styles.ofList__item}
                dangerouslySetInnerHTML={{ __html: itemList }}
              ></li>
            );
          })}
        </ol>
      ) : (
        <ul className={styles.ofListUnordered}>
          {data.items.map((itemList, index) => {
            return (
              <li
                key={`list-key-unordered-${index}`}
                className={styles.ofList__item}
                dangerouslySetInnerHTML={{ __html: itemList }}
              ></li>
            );
          })}
        </ul>
      )}
    </>
  );
};
