import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import type { ArticleTwitterComponent } from "@motain/xpa-proto-files-web/lib/types/news";
import type { Immutable } from "@/types/immutable";
import { isNonNullable } from "@/types/isNonNullable";
import { useScript } from "@/utils/hooks/useScript";
import {
  twitterGlobalKeyNS,
  twitterScriptUrl,
} from "./ArticleTwitter.constants";
import styles from "./ArticleTwitter.module.scss";
import { useStableSchema } from "@/utils/hooks/useStableSchema";

interface TwitterScript {
  widgets: { load: (element: HTMLQuoteElement | null) => void };
}

declare global {
  interface Window {
    [twitterGlobalKeyNS]?: Readonly<TwitterScript>;
  }
}

export const ArticleTwitter: React.FC<Immutable<ArticleTwitterComponent>> = ({
  authorName,
  content,
  link,
  publishedTime,
}) => {
  const { isLoaded: isTwitterLoaded } = useScript(twitterScriptUrl);
  const tweetRef = useRef<HTMLQuoteElement | null>(null);
  const schema = useStableSchema();

  useEffect(() => {
    if (
      isNonNullable(tweetRef.current) &&
      isTwitterLoaded &&
      isNonNullable(schema)
    ) {
      window[twitterGlobalKeyNS]?.widgets.load(tweetRef.current);
    }
  }, [isTwitterLoaded, tweetRef, schema]);

  return (
    <div className={styles.container}>
      <blockquote
        ref={tweetRef}
        className={classnames(styles.tweet, "twitter-tweet")}
        data-theme={schema}
        data-conversation="none"
      >
        <a className={classnames(styles.link, "twitter-tweet")} href={link}>
          {content}
        </a>
        <p className="text-1">
          {authorName}&nbsp;&ndash;&nbsp;
          <time dateTime={publishedTime}>{publishedTime}</time>
        </p>
      </blockquote>
    </div>
  );
};
