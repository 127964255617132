import React from "react";
import type { Immutable } from "@/types/immutable";
import type { ImageObject } from "@motain/xpa-proto-files-web/lib/types/image";
import { ImageWithSets } from "@/components/image-with-sets/ImageWithSets";
import styles from "./ImageWrapper.module.scss";
import { DEFAULT_WIDTH_PER_SCREEN_SIZE } from "@/constants/of-image.constant";
import { tryToResult } from "@/utils/result";
import { ImageRatio } from "@/constants/image-ratio.constants";
import { MEDIA_QUERY } from "@/constants/media-query.constant";
import { imageUrlService } from "@/services/image-url-service";
import { error } from "@/utils/logger";
import type { XpaScreenSize } from "@/types/xpaScreenSize";

export const ImageWrapper: React.FC<Immutable<ImageObject>> = (props) => {
  const maybeRatio = (props.size?.width ?? 0) / (props.size?.height ?? 0);

  const ratio =
    Number.isFinite(maybeRatio) && maybeRatio > 0
      ? maybeRatio
      : ImageRatio.Default3to2;

  const darkModeUrl =
    props.darkModePath === "" ? props.path : props.darkModePath;

  const imageSourceSet = tryToResult(() => {
    const getImgSet = (size: XpaScreenSize) => {
      return {
        media: MEDIA_QUERY[size],
        width: DEFAULT_WIDTH_PER_SCREEN_SIZE[size],
        ratio,
        lightModeUrl: imageUrlService
          .buildImgSrc({
            ratio,
            src: props.path,
            width: DEFAULT_WIDTH_PER_SCREEN_SIZE[size],
          })
          .unwrap(),
        darkModeUrl: imageUrlService
          .buildImgSrc({
            ratio,
            src: darkModeUrl,
            width: DEFAULT_WIDTH_PER_SCREEN_SIZE[size],
          })
          .unwrap(),
      };
    };

    return {
      desktop: getImgSet("desktop"),
      tabletLandscape: getImgSet("tabletLandscape"),
      tabletPortrait: getImgSet("tabletPortrait"),
      mobile: getImgSet("mobile"),
    };
  });

  if (imageSourceSet.isErr()) {
    error(imageSourceSet.error.message, imageSourceSet.error);

    return null;
  }

  return (
    <ImageWithSets
      image={imageSourceSet.value}
      isPreloaded={props.isPreloaded}
      alt={props.alt}
      classNames={styles["media-container__image"] ?? ""}
    />
  );
};
