import useTranslation from "next-translate/useTranslation";
import { Button } from "@/components/button/Button";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { commentsCounterButtonClicked as trackClick } from "../utils/analytics";
import type { CommentsCounter as CommentsCounterType } from "@motain/xpa-proto-files-web/lib/types/comments_counter";
import type { FC } from "react";
import styles from "./CommentsCounter.module.scss";
import { useCommentsCounter } from "@/xpa-components/comments-openweb/components/useCommentsCounter";
import classNames from "classnames";
import { scrollToComments } from "@/xpa-components/comments-openweb/components/scrollToComments";

export const CommentsCounter: FC<CommentsCounterType> = (props) => {
  const { t } = useTranslation("web-payments");
  const { commentSectionId } = props;
  const commentCounter = useCommentsCounter(commentSectionId);

  const handleClick = () => {
    trackClick(commentSectionId);
    scrollToComments(commentSectionId);
  };

  return (
    <Button
      className={styles.wrapper}
      variant={"outline"}
      onClick={handleClick}
    >
      <ImageWithFallback
        src="https://images.onefootball.com/cw/icons/comments-light.svg"
        darkModeSrc="https://images.onefootball.com/cw/icons/comments-dark.svg"
        alt=""
        width={24}
        height={24}
        isPreloaded={false}
      />
      <span className="screen-reader-only">{t`COMMENTS_SECTION_NAV_BUTTON`}</span>
      <span className={classNames(styles.counter, "text-5")}>
        {commentCounter}
      </span>
    </Button>
  );
};
