// See: https://www.avo.app/schemas/8WT8YaEC2F3w5fo31aV0/events/cat/gSKEtScmO
export const event = {
  commentsPageView: "comments_page_view",
  commentPostClicked: "comment_post_clicked",
  commentShared: "comment_shared",
  commentReported: "comment_reported",
  commentReacted: "comment_reacted",
  seeCommentButtonClicked: "see_comment_button_clicked",
} as const;

export const property = {
  commentItemType: "comment_item_type",
  itemId: "item_id",
  providerId: "provider_id",
  commentId: "comment_id",
  commentReactionType: "comment_reaction_type",
} as const;
