import { useEffect, useState } from "react";

export const useScript = (url: string): { isLoaded: boolean } => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (document.querySelector(`[src='${url}']`)) {
      setIsLoaded(true);
      return;
    }

    const script = document.createElement("script");
    script.src = url;
    script.async = true;

    script.onload = () => {
      setIsLoaded(true);
    };

    const node = document.getElementsByTagName("script")[0];
    if (node?.parentNode !== null) {
      node?.parentNode.insertBefore(script, node);
    }

    return () => {
      script.parentNode?.removeChild(script);
    };
  }, [url]);

  return { isLoaded };
};
