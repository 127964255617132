import type { FC } from "react";
import type { ArticleBlockquoteComponent } from "@motain/xpa-proto-files-web/lib/types/news";
import styles from "./ArticleBlockquote.module.scss";

export const ArticleBlockquote: FC<NonNullable<ArticleBlockquoteComponent>> = (
  data,
) => {
  return (
    <>
      <svg className={styles.blockquote__icon} fill="none" viewBox="0 0 24 18">
        <path
          d="M9.625 8.694V18H0V7.653L4.813 0h5.312l-4.75 7.653v1.04h4.25zm13.875 0V18h-9.625V7.653L18.688 0H24l-4.75 7.653v1.04h4.25z"
          fill="currentColor"
        />
      </svg>

      <blockquote className={styles.blockquote__textContainer}>
        <p className={styles.blockquote__text}>{data.text}</p>
      </blockquote>

      {data.authorName && (
        <p className={styles.blockquote__author}>
          <span className={styles.label}>{data.authorName}</span>
        </p>
      )}
    </>
  );
};
