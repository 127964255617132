import { trackingService } from "@/modules/analytics";

import * as Avo from "../types/analytics-avo";
import type * as OpenWeb from "../types/analytics-openweb";

const analytics = {
  track: (event: string, properties: Record<string, string>) => {
    trackingService.sendEvent({
      trackers: [
        {
          kind: "rudderstack",
          extraParams: { name: event },
        },
      ],
      params: properties,
    });
  },
};

export const conversationViewed = (event: OpenWeb.ConversationViewed): void => {
  analytics.track(Avo.event.commentsPageView, {
    [Avo.property.itemId]: event.meta.postId,
    [Avo.property.commentItemType]: "article",
  });
};

export const sendButtonClicked = (event: OpenWeb.ConversationViewed): void => {
  analytics.track(Avo.event.commentPostClicked, {
    [Avo.property.itemId]: event.meta.postId,
    [Avo.property.commentItemType]: "article",
  });
};

export const shareOptionClicked = (
  event: OpenWeb.ShareType,
  commentSectionId: string,
): void => {
  analytics.track(Avo.event.commentShared, {
    [Avo.property.itemId]: commentSectionId,
    [Avo.property.commentId]: event.message_id,
  });
};

export const commentReported = (event: OpenWeb.CommentReported): void => {
  analytics.track(Avo.event.commentReported, {
    [Avo.property.itemId]: event.meta.postId,
    [Avo.property.commentId]: event.detail.message_id,
  });
};

export const upVoteReactionButtonClicked = (
  event: OpenWeb.UpVoteClick,
): void => {
  analytics.track(Avo.event.commentReacted, {
    [Avo.property.itemId]: event.meta.postId,
    [Avo.property.commentId]: event.detail.message_id,
    [Avo.property.commentReactionType]: "up_vote",
  });
};

export const downVoteReactionButtonClicked = (
  event: OpenWeb.DownVoteClick,
): void => {
  analytics.track(Avo.event.commentReacted, {
    [Avo.property.itemId]: event.meta.postId,
    [Avo.property.commentId]: event.detail.message_id,
    [Avo.property.commentReactionType]: "down_vote",
  });
};

export const commentsCounterButtonClicked = (
  commentSectionId: string,
): void => {
  analytics.track(Avo.event.seeCommentButtonClicked, {
    [Avo.property.itemId]: commentSectionId,
    [Avo.property.commentItemType]: "article",
  });
};
