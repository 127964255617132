import { useCallback, useEffect, useState } from "react";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { string, type, is, number, record } from "superstruct";
import { remoteLogger } from "@/utils/remoteLogger";
import { isNonNullable } from "@/types/isNonNullable";
const OPEN_WEB_COMMENTS_SPOT_ID = envBasedConfig.openwebSpotId;

const formatNumberPosts = (commentsNumber: number): string => {
  const commentsAsString = commentsNumber.toString();
  if (commentsAsString.length <= 3) {
    return commentsAsString;
  } else {
    return (commentsNumber / 1000).toFixed(1) + "k";
  }
};

const commentCounterResponseStruct = type({
  spot_id: string(),
  messages_count: record(string(), number()),
});

const buildOpenWebMessageCountUrl = (postsIds: string) =>
  `https://open-api.spot.im/v1/messages-count?spot_id=${OPEN_WEB_COMMENTS_SPOT_ID}&posts_ids=${postsIds}`;

//https://developers.openweb.com/docs/comment-counter
export const useCommentsCounter = (postId: string): string | null => {
  const [userCommentsCounter, setUserCommentsCounter] = useState<string | null>(
    null,
  );

  const getMessageCount = useCallback(async () => {
    try {
      const response = await fetch(buildOpenWebMessageCountUrl(postId));

      if (response.ok) {
        const responseData: unknown = await response.json();
        if (is(responseData, commentCounterResponseStruct)) {
          return responseData.messages_count[postId];
        }
      }
    } catch (error: unknown) {
      remoteLogger.error("Error fetching comments count", error);
    }
    return null;
  }, [postId]);

  useEffect(() => {
    async function fetchMessageCount() {
      const messageCount = await getMessageCount();
      if (isNonNullable(messageCount)) {
        setUserCommentsCounter(formatNumberPosts(messageCount));
      }
    }
    void fetchMessageCount();
  }, [getMessageCount]);

  return userCommentsCounter;
};
