import type { ColorSchema } from "@/context/ColorSchema";
import { useColorSchema } from "@/context/ColorSchema";
import { useEffect, useState } from "react";

/**
 * This hook allow you to delay theme detection until it's 100% correct.
 * If user has enforced light or dark modes, it will return theme immediately,
 * but for "Auto" mode we cannot be sure what system preferences user has until code is executed in browser.
 * That's why for "Auto" mode theme will be set initially to undefined, and then updated to correct value in the browser.
 */
export function useStableSchema(): ColorSchema | undefined {
  const { schema, uiMode, schemaIsStable } = useColorSchema();
  const isAuto = uiMode === "auto";
  const [stableSchema, setStableSchema] = useState<ColorSchema | undefined>(
    isAuto ? undefined : schema,
  );

  useEffect(() => {
    if (isAuto && schemaIsStable) {
      setStableSchema(schema);
    }
  }, [isAuto, schema, schemaIsStable]);

  return stableSchema;
}
