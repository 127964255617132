export const scrollToComments = (commentSectionId: string): void => {
  const conversationElement = document.querySelector(
    `[data-spotim-module="conversation"][data-post-id="${commentSectionId}"]`,
  );
  const conversationDefaultAreaElement = document.querySelector(
    `[data-spot-im-module-default-area="conversation"][data-post-id="${commentSectionId}"`,
  );

  const element = conversationElement ?? conversationDefaultAreaElement;
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
