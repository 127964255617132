import type { FC } from "react";
import React from "react";
import classNames from "classnames";
import type {
  ArticleHeroBanner as ArticleHeroBannerType,
  ArticleHeroBanner_Colors,
} from "@motain/xpa-proto-files-web/lib/types/news";
import styles from "./ArticleHeroBanner.module.scss";
import PatternLeft from "@/public/next-assets/zigzag-pattern-left.svg";
import PatternRight from "@/public/next-assets/zigzag-pattern-right.svg";
import { isNullable } from "@/types/isNullable";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { isNonNullable } from "@/types/isNonNullable";
import { ImageWrapper } from "@/xpa-components/image-wrapper/ImageWrapper";
import { NativeShareComponent } from "@/xpa-components/native-share/NativeShare";
import { BookmarkButton } from "@/components/bookmark-button/BookmarkButton";
import { LiveArticleIndicator } from "@/xpa-components/article-hero-banner/live-article-indicator/LiveArticleIndicator";
import { CommentsCounter } from "@/xpa-components/comments-openweb/components/CommentsCounter";

const DEFAULT_COLORS: ArticleHeroBanner_Colors = {
  pattern: "var(--primary-text-color)",
  text: "var(--primary-text-color)",
  background: "var(--primary-background-color)",
};

export const ArticleHeroBanner: FC<NonNullable<ArticleHeroBannerType>> = (
  props,
) => {
  const {
    provider,
    publishedTime,
    image,
    title,
    colorsDarkTheme = DEFAULT_COLORS,
    colorsLightTheme = DEFAULT_COLORS,
    nativeShare,
    id,
    isBookmarked,
    liveArticleIndicator,
    commentsCounter,
  } = props;

  return (
    <article
      className={classNames(
        styles.articleHeroBanner,
        isNullable(image) && styles.noImage,
      )}
      style={{
        "--color-pattern-dark": colorsDarkTheme.pattern,
        "--color-text-dark": colorsDarkTheme.text,
        "--color-background-dark": colorsDarkTheme.background,
        "--color-pattern-light": colorsLightTheme.pattern,
        "--color-text-light": colorsLightTheme.text,
        "--color-background-light": colorsLightTheme.background,
      }}
    >
      <div className={styles.heroBannerTextComponent}>
        <div
          className={classNames(
            styles.patternContainer,
            styles.patternContainerLeft,
          )}
        >
          <PatternLeft className={styles.pattern} />
        </div>
        <div className={styles.heroBannerTextWrapper}>
          <div className={styles.provider}>
            {provider?.image?.path !== undefined && (
              <ImageWithFallback
                className={styles.providerLogo}
                width={48}
                height={48}
                alt={provider.image.alt}
                src={provider.image.path}
                darkModeSrc={
                  provider.image.darkModePath !== ""
                    ? provider.image.darkModePath
                    : provider.image.path
                }
              />
            )}
            {isNonNullable(provider) && (
              <div>
                <p className={"title-8-bold"}>{provider.name}</p>
                <p
                  className={classNames(
                    "title-8-regular",
                    styles.providerDetails,
                  )}
                >
                  {isNonNullable(provider.name) && provider.name !== "" && (
                    <>
                      {provider.author}
                      <span
                        className={classNames(
                          "title-8-bold",
                          styles.providerDelimiter,
                        )}
                      >
                        &middot;
                      </span>
                    </>
                  )}

                  {isNonNullable(publishedTime) && <span>{publishedTime}</span>}
                </p>
              </div>
            )}
          </div>
          <h2 className={classNames("title-1-bold", styles.articleTitle)}>
            {isNonNullable(liveArticleIndicator) && (
              <LiveArticleIndicator
                {...liveArticleIndicator}
                className={styles.liveIndicator}
              />
            )}
            <span className={styles.articleTitleTextBackground}>
              {title?.text}
            </span>
          </h2>
          <div className={styles.articleCta}>
            {isNonNullable(commentsCounter) && (
              <CommentsCounter {...commentsCounter} />
            )}

            {isNonNullable(isBookmarked) && (
              <BookmarkButton
                isBookmarked={isBookmarked}
                id={id}
                variant={"outline"}
              />
            )}

            {isNonNullable(nativeShare) && (
              <NativeShareComponent {...nativeShare} iconOnly />
            )}
          </div>
        </div>
      </div>

      <div className={styles.heroBannerImageComponent}>
        {isNonNullable(image) && <ImageWrapper {...image} />}
      </div>

      <div className={styles.patternContainerRight}>
        <PatternRight className={styles.pattern} />
      </div>
    </article>
  );
};
